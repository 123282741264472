import React from 'react'
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Current from "../components/global/getdate";
import { Panels } from "../components/panels/panel_list";

const Idx = () => {

return (
  <>
    <Layout>
      <SEO title="ff4500" />
      <Current />
      <Panels />
      <Link className="about_link" to="/about/">?</Link>
    </Layout>
  </>
  )
}

export default Idx
