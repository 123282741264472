import React from "react"
import module from '../../styles/components/modules/date.module.scss';

var today = new Date();
  var mm = today.getMonth() + 1;
  var dd = today.getDate();
  var yyyy = today.getFullYear();
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (dd < 10) {
    dd = '0' + dd;
  }

const Current = () => (
  <div className={module.date_display}>
    <div className={module.month}>{mm}</div>
    <div className={module.day}>{dd}</div>
    <div className={module.year}>{yyyy}</div>
  </div>
)

export default Current