import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import module from '../../styles/components/modules/panel.module.scss';
// import Dump from '../debug/dump';
import Item from './item'

export const Panels = () => {
  const data = useStaticQuery(graphql`
    query PanelQuery {
      panelData: allDataJson(sort: {fields: order, order: ASC}) {
        group(field: order) {
          fieldValue
          totalCount
          edges {
            node {
              id
              name
              order
              items {
                item_name
                item_url
                item_icon
                item_icon_leader
                item_sort_order
                custom_color
                background_color
                foreground_color
                disabled
              }
            }
          }
        }
      }
    }
  `)

  const panel = data.panelData.group

  return (
    <>
      <div className={module.container}>
        {panel.map(( { fieldValue, edges } ) => {
          return (
            <span className={"group group--" + (fieldValue)}>
              {edges.map(( { node } ) => {
                return (
                  <>
                    <span className={module.cat}>{node.name}</span>
                    <Item list={node} />
                  </>
                )
              })}
            </span>
          )
        })}
      </div>
      {/*<Dump data={data}></Dump>*/}
    </>
  )
}
