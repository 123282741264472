import React from 'react'
// import { Redirect } from 'react-router-dom'
// import { useHistory } from 'react-router-dom';
import module from '../../styles/components/modules/panel.module.scss';

const gotoURL=(event)=> {
  if (event.keyCode === 13) {
    console.log(`${event.currentTarget.dataset.div_url}`);
    window.open(`${event.currentTarget.dataset.div_url}`, '_blank');
    // window.location.href=(`${event.currentTarget.dataset.div_url}`);
  }
}

export default ({ list }) => (
  <>
    {list.items.map((items, index) => (
      <>
        {!!items.disabled ? null :
          (<span className={`m_hover ${module.items}`} key={index} role="button" tabindex="0" data-div_url={items.item_url} onKeyDown={(e) => gotoURL(e)}>
            <a href={items.item_url} target="_blank" rel="noopener noreferrer">{items.item_name}</a>
            {/*
            {!!items.item_name ? (<p>{items.item_name}</p>) : null}
            {!!items.item_name ? (<p>{items.item_url}</p>) : null}
            {!!items.item_name ? (<p>{items.item_icon}</p>) : null}
            {!!items.item_name ? (<p>{items.item_icon_leader}</p>) : null}
            {!!items.item_name ? (<p>{items.item_sort_order}</p>) : null}
            {!!items.custom_color ? (<p>TRUE</p>) : null}
            {!!items.custom_color && items.background_color ? (<p>{items.background_color}</p>) : null}
            {!!items.custom_color && items.foreground_color ? (<p>{items.foreground_color}</p>) : null}
            {!!items.disabled ? (<p>DISABLED</p>) : null}
            */}
          </span>)
        }
      </>
    ))}
    <div className="m_highlight">
      <div className="directions">Enter to go to the link</div>
    </div>
  </>
)
